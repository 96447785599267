import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import moment from "moment";

export default {
    data() {
        return {
            parentFilter: [],
            tinyKey: "9l0vh6o626oqntkywmvqf8cmd9d7qxr8d4ky121k44kkkjf0",
            tinyEditorInit: {
                force_br_newlines: true,
                force_p_newlines: false,
                forced_root_block: "", // Needed for 3.x
                branding: false,
                height: 500,
                menubar: false,
                images_dataimg_filter: function (img) {
                    return img.hasAttribute("internal-blob");
                },
                images_upload_url: "image",
                automatic_uploads: false,
                plugins: [
                    "advlist autolink lists code link image imagetools charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code wordcount fullscreen"
                ],
                toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | table | removeformat | code | link | image | fullscreen"
            },
            questions: [
                {value: 0, title: "No"},
                {value: 1, title: "Yes"}
            ],
            statusFilter: [
                {value: "1", title: "Active"},
                {value: "0", title: "Inactive"}
            ],
            isDimensions: false,
            activeTab: 0,
            activeTabInner: 0,
            activeTabDetail: 0,
            activeTabDetailInner: 0,
            activeTabSecond: 0,
            innerEditedItem: {},
            apiTimeout: null,
            mainDataTableSelection: []
        };
    },
    methods: {
        // hasRole(role) {
        //   if (store.state.auth.roles.length !== 0) {
        //     return store.state.auth.roles.some(spec => {
        //       return spec === role;
        //     });
        //   }
        // },

        hasPermission(permission) {
            if (store.state.auth.permissions.length !== 0) {
                return store.state.auth.permissions.some(spec => {
                    return spec === permission;
                });
            }
        },

        getAppUrl() {
            return ApiService.appUrl;
        },

        checkFileExtension(extension) {
            return extension === '.jpg' ||
                extension === '.JPG' ||
                extension === '.png' ||
                extension === '.PNG' ||
                extension === '.jpeg' ||
                extension === '.JPEG' ||
                extension === '.jfif' ||
                extension === '.JFIF';
        },

        isNumber(evt) {
            let charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode !== 46 &&
                charCode > 31 &&
                (charCode < 48 || charCode > 57)
            ) {
                return evt.preventDefault();
            }
            return true;
            // if (!/^[0-9]+$/.test(event.key) || event.key === ".")
            //   return event.preventDefault();
        },

        getDay(date){
            return moment(date).format('dddd');
        },

        formatTime(time) {
            let timeInt = parseInt(time);
            let minutes = time.substring(3,5);

            // you could then add or subtract time here as needed

            if(time > '12:00') {
                return `${timeInt - 12}:${minutes} PM`;
            } else {
                return `${timeInt}:${minutes} AM`;
            }
            // return date ? moment(date, 'h:mm a') : "";
            // return moment(date).format('DD/MM/YYYY, h:mm a')
        },

        formatDateAndTime(date) {
            return date ? moment(date).format("DD-MMM-YYYY, h:mm a") : "";
            // return moment(date).format('DD/MM/YYYY, h:mm a')
        },

        formatMomentDate(date) {
            return date ? moment(date).format("DD-MMM-YYYY") : "";
            // return moment(date).format('DD/MM/YYYY, h:mm a')
        },

        formatDate(date) {
            if (!date) return null
            const [year, day, month] = date.split('-')
            return `${month}-${day}-${year}`
        },

        parseDate(date) {
            if (!date) return null
            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },

        replaceCommaLine(data) {
            //convert string to array and remove whitespace
            let dataToArray = data.split(',').map(item => item.trim());
            //convert array to string replacing comma with new line
            return dataToArray.join("<br>");
        },

        replaceNewLine(data) {
            //convert string to array and remove whitespace
            let dataToArray = data.split('\n').map(item => item.trim());
            //convert array to string replacing comma with new line
            return dataToArray.join("<br>");
        },

        addTS(e) {
            let val = e.target.value;
            if (val) {
                val = val.replace(/[^0-9\.]/g, '');
                let valArr = val.split('.');
                valArr[0] = (parseInt(valArr[0], 10)).toLocaleString();
                val = valArr.join('.');
            } else {
                val = ''
            }
            return val;
        },

        addTSBV(val) {
            if (val) {
                val = val.toString().replace(/[^0-9\.]/g, '');
                let valArr = val.split('.');
                valArr[0] = (parseInt(valArr[0], 10)).toLocaleString();
                val = valArr.join('.');
            } else {
                val = ''
            }
            return val;
        },
    }
}
