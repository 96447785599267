import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("@/view/layout/Layout"),
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () => import("@/view/pages/realstate/dashboard/Dashboard.vue")
                },
                {
                    path: "/users",
                    name: "users",
                    // meta: {
                    //     auth: true,
                    //     permission: "listings.view"
                    // },
                    component: () => import("@/view/pages/realstate/users/Users.vue")
                },
                {
                    path: "/modules",
                    name: "modules",
                    component: () => import("@/view/pages/realstate/modules/Modules.vue")
                },
                {
                    path: "/module/:id",
                    name: "module",
                    // meta: {
                    //     auth: true,
                    //     permission: "listings.view"
                    // },
                    component: () => import("@/view/pages/realstate/modules/ModuleDetails.vue")
                },
            ]
        },
        {
            path: "/error",
            name: "error",
            component: () => import("@/view/pages/error/Error.vue"),
            children: [
                {
                    path: "error-1",
                    name: "error-1",
                    component: () => import("@/view/pages/error/Error-1.vue")
                },
                {
                    path: "error-2",
                    name: "error-2",
                    component: () => import("@/view/pages/error/Error-2.vue")
                },
                {
                    path: "error-3",
                    name: "error-3",
                    component: () => import("@/view/pages/error/Error-3.vue")
                },
                {
                    path: "error-4",
                    name: "error-4",
                    component: () => import("@/view/pages/error/Error-4.vue")
                },
                {
                    path: "error-5",
                    name: "error-5",
                    component: () => import("@/view/pages/error/Error-5.vue")
                },
                {
                    path: "error-6",
                    name: "error-6",
                    component: () => import("@/view/pages/error/Error-6.vue")
                }
            ]
        },
        {
            path: "/",
            component: () => import("@/view/pages/auth/Auth"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/view/pages/auth/Login")
                },
                {
                    name: "forgot-password",
                    path: "/forgot-password",
                    component: () => import("@/view/pages/auth/ForgotPassword")
                },
                {
                    name: "reset-password",
                    path: "/reset-password/:id",
                    component: () => import("@/view/pages/auth/ResetPassword")
                }
            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/error/Error-1.vue")
        }
    ]
});
