import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import store from "@/core/services/store";
import {
    ADD_BODY_CLASSNAME,
    REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module";
import {LOGOUT} from "@/core/services/store/auth.module";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = process.env.VUE_APP_ADMIN_API_URL;
        store.state.appUrl = process.env.VUE_APP_S3_URL;
        // Vue.axios.defaults.baseURL = "https://admin-api.mygreatdubai.com";
    },

    /**
     * Set the default HTTP request headers
     */
    setHeader() {
        Vue.axios.defaults.headers.common[
            "Authorization"
            ] = `Bearer ${JwtService.getToken()}`;
    },

    query(resource, params) {
        return Vue.axios.get(resource, params).catch(error => {
            // console.log(error);
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    doVerify(resource, payload) {
        return new Promise(resolve => {
            // store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            return Vue.axios
                .get(`${resource}`)
                .then(data => {
                    if (data.data._metadata.outcomeCode === 1) {
                        store.dispatch(LOGOUT);
                        return false;
                    }
                    resolve(data);
                    // if (payload.to === "dashboard") {
                    //     store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                    // }
                })
                .catch(error => {
                    // console.log(error);
                    store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                    throw new Error(`[KT] ApiService ${error}`);
                });
        });
    },

    datatable(resource) {
        return new Promise(resolve => {
            return Vue.axios
                .get(`${resource}`)
                .then(data => {
                    if (data.data._metadata.outcomeCode === 1) {
                        store.dispatch(LOGOUT);
                        return false;
                    }
                    resolve(data);
                    store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                    // Scroll page to top on every route change
                    // setTimeout(() => {
                    //     window.scrollTo(0, 0);
                    // }, 100);
                })
                .catch(error => {
                    throw new Error(`[KT] ApiService ${error}`);
                });
        });
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
    get(resource, slug = "") {
        return new Promise(resolve => {
            if (
                resource !== "api/auth/verify"
                && resource !== "api/rolessimple"
                && resource !== "api/purposessimple"
                && resource !== "api/salutationssimple"
                && resource !== "api/adminpermissions"
                && resource !== "api/vendorpermissions"
                && resource !== "api/roadlocationssimple"
                && resource !== "api/inquirystatusessimple"
                && resource !== "api/cardssimple"
                && resource !== "api/permissionssimple"
                && resource !== "api/locationremarkssimple"
                && resource !== "api/developmentssimple"
                && resource !== "api/publishingstatusessimple"
                && resource !== "api/developerssimple"
                && resource !== "api/propertytypessimple"
                && resource !== "api/completionstatusessimple"
                && resource !== "api/developmenttypessimple"
                && resource !== "api/nationalitiessimple"
                && resource !== "api/currenciessimple"
                && resource !== "api/vendorssimple"
                && resource !== "api/cityssimple"
                && resource !== "api/projectfeaturessimple"
                && resource !== "api/countriessimple"
                && resource !== "api/developerssimple"
                && resource !== "api/lifecyclessimple"
                && resource !== "api/specialclassificationssimple"
                && resource !== "api/amenitiessimple"
                && resource !== "api/domainssimple"
                && resource !== "api/sourcessimple"
                && resource !== "api/occupationssimple"
                && resource !== "api/contactrolessimple"
                && resource !== "api/viewssimple"
                && resource !== "api/usercontactssimple"
            ) {
                store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            }
            if (slug !== "") {
                return Vue.axios
                    .get(`${resource}/${slug}`)
                    .then(data => {
                        if (data.data._metadata.outcomeCode === 1) {
                            store.dispatch(LOGOUT);
                            return false;
                        }
                        resolve(data);
                        store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                    })
                    .catch(error => {
                        store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                        throw new Error(`[KT] ApiService ${error}`);
                    });
            } else {
                return Vue.axios
                    .get(`${resource}`)
                    .then(data => {
                        if (data.data._metadata.outcomeCode === 1) {
                            store.dispatch(LOGOUT);
                            return false;
                        }
                        resolve(data);
                        store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                    })
                    .catch(error => {
                        // console.log(error);
                        store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                        throw new Error(`[KT] ApiService ${error}`);
                    });
            }
        });
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params) {
        if (resource !== "api/contactslist") {
            store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        }
        return new Promise(resolve => {
            return Vue.axios
                .post(`${resource}`, params)
                .then(data => {
                    if (data.data._metadata.outcomeCode === 1) {
                        store.dispatch(LOGOUT);
                        return false;
                    }
                    resolve(data);
                    store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                })
                .catch(error => {
                    store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                    throw new Error(`[RWV] ApiService ${error}`);
                });
        });
    },

    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        return new Promise(resolve => {
            return Vue.axios
                .post(`${resource}/${slug}`, params)
                .then(data => {
                    if (data.data._metadata.outcomeCode === 1) {
                        store.dispatch(LOGOUT);
                        return false;
                    }
                    resolve(data);
                    store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                })
                .catch(error => {
                    store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                    throw new Error(`[RWV] ApiService ${error}`);
                });
        });
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        return Vue.axios
            .delete(resource)
            .then(data => {
                if (data.data._metadata.outcomeCode === 1) {
                    store.dispatch(LOGOUT);
                    return false;
                }
            })
            .catch(error => {
                // console.log(error);
                throw new Error(`[RWV] ApiService ${error}`);
            });
    }
};

export default ApiService;
