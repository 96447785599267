import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const DASHBOARDS_LIST = "dashboards_list";
export const CREATE_DASHBOARD = "create_dashboard";
export const UPDATE_DASHBOARD = "update_dashboard";
export const DELETE_DASHBOARD = "delete_dashboard";
export const GET_DASHBOARD_DETAILS = "get_dashboard_details";
export const CLEAR_DASHBOARD_ERRORS = "clearDashboardErrors";

// mutation types
export const SET_DASHBOARD_ERROR = "setDashboardError";
export const REMOVE_DASHBOARD_ERRORS = "removeDashboardErrors";

const state = {
    errors: null,
};


const actions = {
    [DASHBOARDS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/dashboards?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DASHBOARD_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [GET_DASHBOARD_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getdashboarddetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DASHBOARD_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [CREATE_DASHBOARD](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createdashboard", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_DASHBOARD_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [UPDATE_DASHBOARD](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatedashboard", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_DASHBOARD_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [DELETE_DASHBOARD](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletedashboard", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_DASHBOARD_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [CLEAR_DASHBOARD_ERRORS](context) {
        context.commit(REMOVE_DASHBOARD_ERRORS);
    },
};

const mutations = {
    [SET_DASHBOARD_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_DASHBOARD_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};
